import common from './element/mixins';

export default {

  props: {
    activeData: Object,
  },
  methods: {
    getSumFields () {
      let ret = [];
      if (this.activeData.__config__.children && this.activeData.__config__.children[0] && this.activeData.__config__.children[0].fields) {
        ret = this.activeData.__config__.children[0].fields || [];
      }
      return ret.filter(t=>t.__config__?.tag === 'el-input-number' && t.__config__?.visible !== false);
    },
  },
  mixins: [common],
  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        {
          this.activeData.__config__.tag === 'c-spare-part-list'
            ? <el-form-item label="业务类型" >
              <el-select
                v-model={this.activeData.businessType}
                placeholder="请选择业务类型"
                style={{ width: '100%' }}
              >
                <el-option
                  label="一般业务"
                  value={1}
                />

                <el-option
                  label="传统业务"
                  value={2}
                />
              </el-select>
            </el-form-item>
            : null
        }

        <el-divider>统计</el-divider>
        <el-form-item label="合计项">
          <el-checkbox-group v-model={this.activeData.sumBy}>
            {
              this.getSumFields().map(t=>{
                return <el-checkbox label={t.__config__.renderKey} value={t.__config__.renderKey}>{t.__config__.label} </el-checkbox>;
              })
            }
          </el-checkbox-group>
        </el-form-item>
      </div>
    );
  },
};
