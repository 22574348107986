import common from './mixins';
import _ from 'lodash';
export default {
  mixins: [common],
  methods: {
    reCalcDefaultValue () {
      let hasValue = val=>val !== null && val !== undefined;
      if (!hasValue(this.activeData.precision)) {
        this.$nextTick(()=>{
          this.activeData.precision = 0;
        });
      }
      let precision = 0;
      if (hasValue(this.activeData.precision)) {
        precision = this.activeData.precision;
      }
      let config = this.activeData.__config__;
      if (hasValue(config.defaultValue)) {
        let formatter = val=>_.ceil(val, precision);
        config.defaultValue = formatter(config.defaultValue);
        if (hasValue(this.activeData.max) && config.defaultValue >= this.activeData.max) {
          config.defaultValue = this.activeData.max;
          return;
        }
        if (hasValue(this.activeData.min) && config.defaultValue <= this.activeData.min) {
          config.defaultValue = this.activeData.min;
          return;
        }
      }
    },
  },
  props: {
    activeData: Object,
  },

  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-input-number
            v-model={this.activeData.__config__.defaultValue}
            placeholder="请输入默认值"
            min={this.activeData.min} max={this.activeData.max}
            precision={this.activeData.precision}/>
        </el-form-item>
        <el-form-item label="步长">
          <el-input-number v-model={this.activeData.step} placeholder="步数" />
        </el-form-item>
        <el-form-item label="精度">
          <el-input-number v-model={this.activeData.precision} min={0} placeholder="精度" onChange={this.reCalcDefaultValue}/>
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        <el-form-item label="最小值">
          <el-input-number v-model={this.activeData.min} max={this.activeData.max} placeholder="最小值" onChange={this.reCalcDefaultValue}/>
        </el-form-item>
        <el-form-item label="最大值">
          <el-input-number v-model={this.activeData.max} min={this.activeData.min} placeholder="最大值" onChange={this.reCalcDefaultValue}/>
        </el-form-item>
      </div>
    );
  },
};
