<template>
  <div>
    <el-input
      ref="input"
      :size="size"
      readonly
      disabled
      :value="defaultValue"
      :placeholder="placeholder"
      v-if="readonly"
    />
    <el-select
      :size="size"
      class="w-100"
      v-else
      v-model.trim="defaultValue"
      filterable
      clearable
      remote
      :popperClass="popperClass"
      :placeholder="placeholder"
      :remote-method="getList"
      @visible-change="handleVisibleChange"
      @change="hendleChange"
      @clear="hendleChange"
      :loading="loading"
    >
      <el-option v-for="item in optionList" :key="item.id" :label="item.name" :value="item.id" :disabled="item.workStatus ===  'VACATION'">
        <div v-if="showDepartmt">
          <span style="float: left">{{ item.name }}
            <span style=" color: #8492a6; font-size: 12px">({{item.departmentName}})</span>
          </span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.workStatusName }}</span>
        </div>
        <div v-else>
          <span :style="{float: 'left', color: item.workStatus === 'VACATION' ? '#C0C4CC': ''}">
            {{ item.name }}
            <span :style="{color: item.workStatus === 'VACATION' ? '#C0C4CC' : '#8492a6', fontSize: '13px'}">{{ item.workStatusName }}</span>
          </span>
          <span :style="{float: 'right', color: item.workStatus === 'VACATION' ? '#C0C4CC' : '#8492a6', fontSize: '12px'}">
            已服务该设备工单数: {{ item.count === null ? '--' : item.count }}
          </span>
        </div>
        <br />
        <div v-if="!showDepartmt">
          <el-tag style="margin-right: 6px;" type="info" v-for="tag in (item.labels || [])" :key="tag">{{tag}}</el-tag>
        </div>
      </el-option>
    </el-select>
    <span v-if="!showDepartmt" style="color: red; font-size: 12px">*无法指派工单给休假中的员工</span>
  </div>
</template>
<script>
import UserApi from '@/api/user';
export default {
  name: 'USER_SELECTOR',
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
    },
    size: {
      type: String,
      default: 'small',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    popperClass: String,
    placeholder: {
      type: String,
      default: '请选择员工',
    },
    deviceId: {
      type: String,
      default: '',
    },
    workOrderType: {
      type: String,
      default: '',
    },
    showDepartmt: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      optionList: [],
      loading: false,
      defaultValue: this.name,
    };
  },
  watch: {
    name: function (val) {
      if (val !== this.defaultValue) {
        this.defaultValue = val;
      }
    },
  },
  methods: {
    getList (keyword) {
      this.loading = true;

      this.optionList = [];

      UserApi.getUserList({
        name: keyword,
        findDepartmentFlag: true,
        pageNum: 1,
        pageSize: 10,
        accountStatus: 'ACTIVE',
        status: 'ON_JOB',
        deviceId: this.deviceId,
        workOrderType: this.workOrderType,
      })
        .then(res => {
          this.optionList = res.body.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    hendleChange (id) {
      let item = this.optionList.find(t => t.id === id);

      if (item) {
        this.$emit('input', item.id);
        this.$emit('update:name', item.name);
        this.$emit('change', item);
      } else {
        this.$emit('input', null);
        this.$emit('update:name', null);
        this.$emit('change', null);
      }
    },
    handleVisibleChange (visible) {
      if (visible) {
        this.getList();
      }
    },
  },
};
</script>
<style lang="scss">
  .el-select-dropdown__item {
    height: auto;
    min-height: 34px;
    overflow: visible;
  }
</style>
