<template>
  <el-dialog :title="title"
    width="600px"
    :close-on-click-modal="false"
    :visible="true"
    @close="handleClose" >
    <div class="child_form_filter_dialog">
      <VirtualScrollList v-if="filters && filters[0]"
        ref="scrollList"
        :size="44"
        :remain="5" >
        <div class="child_form_filter_item"
          v-for="(i,index) in filters"
          :key="i._key">
          <el-select v-model="i.relatedFormFillField"
            class="flex-fill"
            :popper-append-to-body="false"
            @change="handleResetBindValue(i)"
            size="small">
            <el-option  v-for="t in sourceFieldList"
              :key="t.columnKey"
              :label="t.columnName"
              :value="t.columnKey"/>
          </el-select>
          <span class="ml-1 mr-1">=</span>
          <el-select v-model="i.valueType"
            size="small"
            :popper-append-to-body="false"
            @change="handleResetBindValue(i)"
            style="width:80px">
            <el-option value="1" label="值"></el-option>
            <el-option :value="undefined" label="字段"></el-option>
          </el-select>
          <Render class="ml-1 mr-1 flex-fill"
            v-if="i.valueType"
            :formModel="getDefaultValue(i)"
            :conf="normalLizeConfig(i)">
          </Render>
          <el-select  v-else
            class="ml-1 mr-1 flex-fill"
            :popper-append-to-body="false"
            v-model="i.relatedFormQueryBy"
            size="small">
             <el-option v-for="t in filtedBindFieldList(i.relatedFormFillField)"
              :key="t.__config__.renderKey"
              :label="t.__config__.label"
              :value="t.__config__.renderKey">
            </el-option>
          </el-select>
          <i class="el-icon-remove-outline" @click="handleRemove(index)"></i>
        </div>
      </VirtualScrollList>
      <el-empty v-else description="暂无数据" :image-size="80" class="mb-2"></el-empty>
      <div class="child_form_filter_new" @click="handleNew">
        <i class="el-icon-plus"></i>
      </div>
    </div>
    <span slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import _ from 'lodash';
import Render from '@/components/form-generator/render/render.js';
import { getDefaultValueByTag } from '@/components/form-generator/utils/component.js';
import VirtualScrollList from 'vue-virtual-scroll-list';
import getComponentMatcher from '../../utils/componentMatcher';
export default {
  name: 'childFormConfigFilterDialog',
  components: {
    VirtualScrollList,
    Render,
  },
  props: {
    title: {
      type: String,
      default: '数据过滤条件',
    },
    value: Array,
    sourceFieldList: {
      type: Array,
      default: ()=>[],
    },
    bindFiledList: {
      type: Array,
      default: ()=>[],
    },
  },
  data () {
    return {
      filters: _.cloneDeep(this.value) || [],
    };
  },
  methods: {
    filtedBindFieldList (sourceField) {
      let column = this.sourceFieldList.find(t=>t.columnKey === sourceField);
      const tag = column?.columnValue?.__config__?.tag;
      if (!column || !tag) {
        return [];
      } else {
        const componentMatcher = getComponentMatcher(column?.columnValue);
        return this.bindFiledList.filter(componentMatcher);
      }
    },
    getDefaultValue (i) {
      if (i.relatedFormFillField === null || i.relatedFormFillField === undefined) {
        return {};
      }
      const bindModel = {};
      if (i.relatedFormQueryBy === null || i.relatedFormQueryBy === undefined) {
        let column = this.sourceFieldList.find(t=>t.columnKey === i.relatedFormFillField);
        bindModel[i.relatedFormFillField] = getDefaultValueByTag(column.columnValue);
      } else {
        bindModel[i.relatedFormFillField] = i.relatedFormQueryBy;
      }
      return bindModel;
    },
    handleResetBindValue (i) {
      i.relatedFormQueryBy = null;
    },
    normalLizeConfig (i) {
      let column = this.sourceFieldList.find(t=>t.columnKey === i.relatedFormFillField);
      let schema = column?.columnValue;
      if (!schema) {
        // 若未匹配成功暂用span展示
        schema = {__config__: {
          tag: 'span',
        }};
      } else {
        const config = schema.__config__;
        schema.disabled = false;
        if (config.hasOwnProperty('filterParam')) {
          config.filterParam = null;
        }
        if (config?.tag === 'span') {
          schema.__config__.tag = 'el-input';
        } else if (config?.tag === 'el-date-picker' || config?.tag === 'el-time-picker') {
          schema = {
            __config__: config,
            style: {
              width: '100%',
            },
            type: 'daterange',
            defaultTime: ['00:00:00', '23:59:59'],
            valueFormat: 'timestamp',
            'range-separator': '至',
            'start-placeholder': '开始日期',
            'end-placeholder': '结束日期',
          };
        }
        schema.placeholder
        = schema.placeholder || `${schema.__config__.tag === 'el-input' ? '请输入' : '请选择'}${schema.__config__.label}`;
      }
      return {
        ...schema,
        on: {
          input: val => {
            i.relatedFormQueryBy = val;
          },
        },
      };
    },
    handleRemove (index) {
      this.filters.splice(index, 1);
    },
    handleNew () {
      this.filters.push({
        _key: `${new Date().getTime()}_${this.filters.length}`,
        relatedFormFillField: null,
        relatedFormQueryBy: null,
      });
      this.$nextTick(()=>{
        this.$refs.scrollList.forceRender();
      });
    },
    handleClose () {
      this.$emit('close');
    },
    handleConfirm () {
      if (this.filters.some(t=>!t.relatedFormFillField || !t.relatedFormQueryBy)) {
        this.$message.error('请补全过滤条件设置');
        return;
      }
      this.$emit('input', this.filters);
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
  .child_form_filter_dialog {
    overflow: hidden;
    &>div {
      max-height: 240px;
      overflow: auto;
      padding: 0 32px;
      .child_form_filter_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;

        &>*:nth-child(1) {
          width: 180px!important;
        }
        &>*:nth-child(4){
          width: 240px!important;
        }

        .el-icon-remove-outline {
          font-size: 16px;
          cursor: pointer;
          color: $danger-color;
        }
      }
    }

    .child_form_filter_new {
      border: 1px dashed $primary-color;
      border-radius: 4px;
      width: 70%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 0;
      color: $primary-color;
      cursor: pointer;
    }
  }
</style>
