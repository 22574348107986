import common from './mixins';
export default {
  mixins: [common],
  props: {
    activeData: Object,
  },
  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="占位提示" >
          <el-input v-model={this.activeData.placeholder} placeholder="请输入占位提示" />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-time-picker v-model={this.activeData.__config__.defaultValue}
            class="w-100"
            value-format={this.activeData['value-format']}
            placeholder="请选择默认值"
            format={this.activeData.format}
            is-range={this.activeData['is-range']}
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item label="时间格式">
          {this.activeData.format}
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        {
          this.activeData['is-range']
            ? <el-form-item label="分隔符">
              <el-input v-model={this.activeData['range-separator']} placeholder="请输入分隔符" />
            </el-form-item>
            : null
        }
      </div>
    );
  },
};
