<script>
import DeviceSelector from '@/components/tableCommonSelector';
import mixin from './mixin';
export default {
  // 公共设备档案 系统组件
  name: 'CDeviceCommonSelector',
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    customerId: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '请选择设备',
    },
  },
  components: {
    DeviceSelector,
  },
  data () {
    return {
      deviceId: this.value?.id,
      deviceName: this.value?.name,
    };
  },
  watch: {
    value () {
      this.deviceId = this.value?.id;
      this.deviceName = this.value?.name;
    },
  },
  methods: {
    handleDeviceChanged (data) {
      this.deviceId = data?.id;
      this.deviceName = data?.name;
      this.$emit('input', data);
    },
  },
  render () {
    return (
      <device-selector
        size={this.inputSize}
        placeholder={this.placeholder}
        value={this.deviceId}
        name={this.deviceName}
        disabled={this.inputDisabled}
        customerId={this.customerId}
        onChange={this.handleDeviceChanged}></device-selector>
    );
  },
};
</script>
