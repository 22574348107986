import common from './mixins';
export default {
  mixins: [common],
  props: {
    activeData: Object,
  },
  computed: {
    acceptList: {
      get () {
        if (this.activeData.accept) {
          return this.activeData.accept.split(',');
        } else {
          return [];
        }
      },
      set (val) {
        if (val) {
          this.activeData.accept = val.join(',');
        } else {
          this.activeData.accept = null;
        }
      },
    },
  },

  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="文件类型">
          <el-select
            v-model={this.acceptList}
            placeholder="请选择文件类型"
            style={{ width: '100%' }}
            multiple
            clearable
          >
            <el-option label="png" value="image/png" />
            <el-option label="jpeg" value="image/jpeg" />
            <el-option label="jpg" value="image/jpg" />
            <el-option label="bmp" value="image/bmp" />
            <el-option label="XLS" value="application/vnd.ms-excel" />
            <el-option label="XLSX" value="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
            <el-option label="DOC" value="application/msword" />
            <el-option label="DOCX" value="application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
            <el-option label="pdf" value="application/pdf" />
          </el-select>
        </el-form-item>
        <el-form-item label="文件大小">
          <el-input type="number" v-model={this.activeData.maxSize} placeholder="请输入文件大小" >
            <span slot="append">MB</span>
          </el-input>
        </el-form-item>
        <el-form-item label="列表类型">
          <el-radio-group v-model={this.activeData['type']} size="small">
            <el-radio-button label="single">
                text
            </el-radio-button>
            <el-radio-button label="list">
                picture-card
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        {
          this.activeData['type'] !== 'list'
            ? <el-form-item label="按钮文字" >
              <el-input v-model={this.activeData.buttonText} placeholder="请输入按钮文字" />
            </el-form-item> : null
        }
        <el-form-item label="多选文件">
          <el-switch v-model={this.activeData.multiple} />
        </el-form-item>

        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        <el-form-item label="最大数量">
          <el-input-number v-model={this.activeData.limit} placeholder="请输入最大数量" step={1} precision={0}>
          </el-input-number>
        </el-form-item>
      </div>
    );
  },
};
