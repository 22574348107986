import common from './mixins';
export default {
  mixins: [common],
  methods: {
  },
  props: {
    activeData: Object,
  },

  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-input-number
            v-model={this.activeData.__config__.defaultValue}
            placeholder="请输入默认值"
            min={0}
            max={this.activeData.max}
            step={this.activeData['allow-half'] ? 0.5 : 0}/>
        </el-form-item>
        <el-form-item label="最大值">
          <el-input-number v-model={this.activeData.max} placeholder="最大值" step={1} precision={0} />
        </el-form-item>
        <el-form-item label="允许半选">
          <el-switch v-model={this.activeData['allow-half']} />
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
      </div>
    );
  },
};
