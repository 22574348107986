import common from './mixins';
export default {
  mixins: [common],
  methods: {
    onSwitchValueInput (val, name) {
      if (['true', 'false'].indexOf(val) > -1) {
        this.$set(this.activeData, name, JSON.parse(val));
      } else {
        this.$set(this.activeData, name, isNaN(val) ? val : +val);
      }
    },
  },
  props: {
    activeData: Object,
  },

  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-select v-model={this.activeData.__config__.defaultValue}>
            <el-option value={null} label="无"></el-option>
            <el-option value={true} label="开"></el-option>
            <el-option value={false} label="关"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开启颜色">
          <el-color-picker v-model={this.activeData['active-color']} />
        </el-form-item>
        <el-form-item label="关闭颜色">
          <el-color-picker v-model={this.activeData['inactive-color']} />
        </el-form-item>
        <el-form-item label="开启提示">
          <el-input v-model={this.activeData['active-text']} placeholder="请输入开启提示" />
        </el-form-item>
        <el-form-item label="关闭提示">
          <el-input v-model={this.activeData['inactive-text']} placeholder="请输入关闭提示" />
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
      </div>
    );
  },
};
