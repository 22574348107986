
<script>
// import mixins from './mixins.js';
export default {
  // mixins: [mixins],
  props: {
    activeData: Object,
  },
  methods: {
    changeRenderKey (val, type) {
      this.activeData[type] = val;
    },
    spanChange (val) {
      this.formConf.span = val;
    },
    handleAddRegex () {
      this.activeData.__config__.regList.push({
        pattern: '',
        message: '',
      });
    },
    setOptionValue (item, val) {
      item.value = isNaN(val) ? val : +val;
    },
    setLinkageValueHandle (val, name) {
      this.$set(this.activeData, name, val);
    },
  },
  render () {
    return (<div>
      <el-form-item label="标题">
        <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" onInput={(val)=> { this.changeRenderKey(val, '__config__.label'); }} />
      </el-form-item>
      <el-form-item label="显示标题" >
        <el-switch v-model={this.activeData.__config__.showLabel} />
      </el-form-item>
      <el-form-item label="占位提示">
        <el-input v-model={this.activeData.addressPlaceholder} placeholder="请输入占位提示" onInput={(val)=> { this.changeRenderKey(val, 'addressPlaceholder'); }} />
      </el-form-item>
      <el-form-item label="表单栅格">
        <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
          <el-radio-button label={6}></el-radio-button>
          <el-radio-button label={8}></el-radio-button>
          <el-radio-button label={12}></el-radio-button>
          <el-radio-button label={24}></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="能否清空">
        <el-switch v-model={this.activeData.clearable} />
      </el-form-item>
      <el-form-item label="是否必填">
        <el-switch v-model={this.activeData.__config__.required} />
      </el-form-item>
      <el-form-item label="地址类型">
        <el-select v-model={this.activeData.addresstype} onChange={val => { this.setLinkageValueHandle(val, 'addresstype'); }}>
          <el-option value={4} label="省/市/区/详细地址" />
          <el-option value={3} label="省/市/区" />
          <el-option value={2} label="省/市" />
          <el-option value={1} label="省" />
        </el-select>
      </el-form-item>
    </div>);
  },
};
</script>
