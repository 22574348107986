import common from './mixins';
import ComponentSelector from '../common/componentSelector.vue';

const SUPPORT_TAG = ['el-input-number', 'el-input', 'span'];
export default {
  mixins: [common],
  components: {
    ComponentSelector,
  },
  props: {
    activeData: Object,
  },
  methods: {
    handleDataFilter (filter) {
      this.$set(this.activeData.__config__, 'from', filter);
    },
  },
  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="占位提示">
          <el-input v-model={this.activeData.placeholder} placeholder="请输入占位提示"/>
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-radio-group v-model={this.activeData.__config__.isFixedValue} onChange={this.handleDefaultValueTypeChange}>
            <el-radio-button label={1}>固定</el-radio-button>
            <el-radio-button label={0}>动态</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item >
          {
            this.activeData.__config__.isFixedValue
              ? <el-input
                v-model={this.activeData.__config__.defaultValue}
                placeholder="请输入默认值"
              />
              : <component-selector
                fieldFilter={[...SUPPORT_TAG]}
                componentFilter={[...SUPPORT_TAG]}
                value={this.activeData.__config__.from}
                current={this.activeData}
                onChange={this.handleDataFilter}/>
          }

        </el-form-item>
        {/* <el-form-item label="默认值">
          <el-input
            v-model={this.activeData.__config__.defaultValue}
            placeholder="请输入默认值"
          />
        </el-form-item> */}
        <el-form-item label="输入统计">
          <el-switch v-model={this.activeData['show-word-limit']} />
        </el-form-item>
        {
          this.activeData.type === 'textarea'
            ? null
            : <el-form-item label="能否清空">
              <el-switch v-model={this.activeData.clearable} />
            </el-form-item>
        }
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        <el-form-item label="最多输入">
          <el-input v-model={this.activeData.maxlength} placeholder="请输入字符长度">
            <template slot="append">
                个字符
            </template>
          </el-input>
        </el-form-item>
        <el-divider>正则校验</el-divider>
        {
          this.activeData.__config__.regList ? this.activeData.__config__.regList.map((item, index)=>{
            return (
              <div key={index} class="reg-item" >
                <span class="close-btn" onClick={this.handleRemoveRegex.bind(this.methods, index)}>
                  <i class="el-icon-close" />
                </span>
                <el-form-item label="表达式">
                  <el-input v-model={item.pattern} placeholder="请输入正则" />
                </el-form-item>
                <el-form-item label="错误提示" style="margin-bottom:0">
                  <el-input v-model={item.message} placeholder="请输入错误提示" />
                </el-form-item>
              </div>
            );
          }) : null
        }
        <div style="margin-left: 20px">
          <el-button icon="el-icon-circle-plus-outline" type="text" onClick={this.handleAddRegex}>
            添加规则
          </el-button>
        </div>
      </div>
    );
  },
};
