<template>
  <vxe-table
    border
    :size="inputSize"
    align="center"
    auto-resize
    resizable
    :show-header="false"
    :data="tableData">
    <vxe-column field="name" title="保养项目" width="180px" align="center"></vxe-column>
    <vxe-column field="children" title="保养项目">
      <template v-slot:default="{ row }">
        <div class="mt_item_cell">
            <div v-for="i in row.children" :key="i.id" class="d-flex align-items-center ">
            <div class="text-align-center flex-shrink-0" >{{i.name}}</div>
            <div class="d-flex flex-wrap flex-fill ">
              <template v-for="n in i.children" >
                <el-checkbox v-if="n.type === 'checkbox'" :key="n.id" :value="n.chooseFlag">{{n.name}}</el-checkbox>
                <span class="ml-2 mr-2" v-if="n.type === 'input'" :key="n.id">{{n.name}}：{{n.value || '--'}}</span>
              </template>
            </div>
          </div>
        </div>
      </template>
    </vxe-column>
  </vxe-table>
</template>

<script>
import mixin from './mixin';
export default {
  // 保养项目 系统组件
  name: 'c-maintenance-list',
  mixins: [mixin],
  props: {
    value: {
      type: Array,
    },
  },
  data () {
    return {
      tableData: this.value,
    };
  },
};
</script>
<style scoped lang="scss">
 .mt_item_cell {
  margin: -8px -10px;

  &>div+div{
    border-top:1px solid #ebeef5;
  }

  &>div{
    line-height: 40px;

    &>div:first-child {
      width:200px;
    }
    &>div:last-child {
      padding-left: 16px;
      border-left:1px solid #ebeef5;
    }
  }
}
</style>
