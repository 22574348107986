<template>
  <div class="selector">
    <el-popover
      :disabled="inputDisabled"
      trigger="manual"
      placement="bottom-start"
      @show="show"
      v-clickoutside="() => (visible = false)"
      :value="visible"
    >
      <div ref="popoverContainer">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="activeTabsNameSen" name="second"><div></div></el-tab-pane>
          <el-tab-pane :label="activeTabsNameFir" name="first"></el-tab-pane>
        </el-tabs>
        <el-table
          ref="table"
          max-height="300"
          :data="tableData"
          highlight-current-row
          style="width: 100%; border: 1px solid rgba(36, 40, 50, 0.12); border-radius: 3px"
          @row-click="handleRowClick"
        >
          <el-table-column prop="name" label="设备名称" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="spec" label="规格型号" width="100" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brand" label="品牌" width="140" show-overflow-tooltip></el-table-column>
          <el-table-column prop="enterpriseName" label="生产商" width="180" show-overflow-tooltip></el-table-column>
        </el-table>
        <el-pagination
          small
          @current-change="handleCurrentChange"
          :page-size="params.pageSize"
          :current-page.sync="currentPage"
          layout="prev, pager,next,total"
          :total="total"
          style="text-align: end"
        ></el-pagination>
      </div>
      <el-input
        slot="reference"
        ref="input"
        @focus="visible = true"
        :disabled="inputDisabled"
        :value="defaultValue"
        clearable
        @clear="clear"
        @input="handleInput($event)"
        :placeholder="placeholder"
      >
        <template slot="append" v-if="!inputDisabled">
          <slot name="append"></slot>
        </template>
      </el-input>
    </el-popover>
  </div>
</template>
<script>
import DeviceApi from '@/api/device';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import _ from 'lodash';
export default {
  name: 'TABLE_SELECTOR',
  directives: {
    Clickoutside,
  },
  props: {
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: '请输入设备名称，规格，分类，品牌进行查询',
    },
  },
  data () {
    return {
      activeName: 'second',
      activeTabsNameFir: '小紫医疗设备库',
      activeTabsNameSen: '产品档案',
      tableData: [],
      params: {
        pageFlag: true,
        allMatching: true,
        enable: false,
        name: '',
        pageNum: 1,
        pageSize: 10,
      },
      defaultValue: this.name,
      total: 0,
      selected: this.device,
      currentPage: 1,
      visible: false,
      deviceData: [],
      productData: [],
    };
  },
  watch: {
    name: function (val) {
      if (val !== this.defaultValue) {
        this.defaultValue = val;
      }
    },
    device: function (val) {
      this.selected = val;
    },
  },
  inject: {
    elForm: {
      default: '',
    },
  },
  computed: {
    inputDisabled () {
      return this.disabled || (this.elForm || {}).disabled;
    },
  },
  methods: {
    handleInput (e) {
      this.params.name = e;
      this.$emit('update:name', e);
      this.defaultValue = e ?? '';
      const snapParam = this.defaultValue !== '' ? { name: this.defaultValue } : {};
      this.$emit('change', snapParam);
      this.debounHandle();
    },
    debounHandle: _.debounce(function () {
      this.currentPage = 1;
      this.params.pageNum = 1;
      this.getList();
      this.getProductList();
    }, 300),
    resultHandle () {
      if (this.selected.id) {
        const row = this.tableData.find(i => i.id === this.selected.id);
        this.$refs.table.setCurrentRow(row);
      }
    },
    getProductList () {
      DeviceApi.productArchives(this.params).then(({ body }) => {
        this.activeTabsNameSen = `产品档案(${body.total})`;
        this.productData = _.cloneDeep(body);
        if (this.activeName === 'second') {
          this.tableData = body.list || [];
          this.total = body.total || 0;
        }
        this.resultHandle();
      });
    },
    getList () {
      DeviceApi.searchDevice(this.params).then(({ body }) => {
        this.activeTabsNameFir = `小紫医疗设备库(${body.total})`;
        this.deviceData = _.cloneDeep(body);
        if (this.activeName === 'first') {
          this.tableData = body.list || [];
          this.total = body.total || 0;
        }
        this.resultHandle();
      });
    },
    async handleClick ({ name }) {
      this.currentPage = 1;
      this.params.pageNum = 1;
      switch (name) {
      case 'first':
        this.getList();
        break;
      case 'second':
        this.getProductList();
        break;
      default:
        this.tableData = [];
        this.total = 0;
        break;
      }
    },
    handleCurrentChange (val) {
      this.params.pageNum = val;
      this.activeName === 'first' ? this.getList() : this.getProductList();
    },
    handleRowClick (row) {
      this.selected = row;
      this.$emit('change', row);
      this.visible = false;
    },
    show () {
      this.currentPage = 1;
      this.params.pageNum = 1;
      this.params.name = this.name;
      this.getList();
      this.getProductList();
    },
    clear () {
      this.selected = {};
      this.$emit('update:name', '');
      this.$emit('change', this.selected);
    },
  },
  mounted () {
    this.popperElm = this.$refs.popoverContainer;
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-tabs__nav-wrap::after {
  background: transparent;
}
</style>
