export default {
  name: 'form-generator-components',
  props: {
    disabled: Boolean,
    size: {
      type: String,
    },
    readonly: Boolean,
  },
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  computed: {
    _elFormItemSize () {
      return (this.elFormItem || {}).elFormItemSize;
    },
    inputSize () {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled () {
      return this.disabled || (this.elForm || {}).disabled;
    },
  },
};
