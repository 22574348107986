import Enum from '@/utils/Enum';

const OrderDeviceStatusEnumList = [
  {
    value: 'NORMAL_USE',
    name: '正常使用',
  },
  {
    value: 'DEAD_HALT',
    name: '完全停机',
  },
  {
    value: 'LIMITED_USE',
    name: '局限使用',
  },
];

export default new Enum(OrderDeviceStatusEnumList);
export { OrderDeviceStatusEnumList };
