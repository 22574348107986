import common from './element/mixins';
import ComponentSelector from './common/componentSelector.vue';

const SUPPORT_TAG = ['el-input-number', 'el-input', 'span'];
export default {
  components: {
    ComponentSelector,
  },
  props: {
    activeData: Object,
  },
  methods: {
    handleDataFilter (filter) {
      this.$set(this.activeData.__config__, 'from', filter);
    },
  },
  mixins: [common],
  render () {
    // 字符串可绑定字符串或者input、input-number
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-radio-group v-model={this.activeData.__config__.isFixedValue}>
            <el-radio-button label={1}>固定</el-radio-button>
            <el-radio-button label={0}>动态</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item >
          {
            this.activeData.__config__.isFixedValue
              ? <el-input
                v-model={this.activeData.__config__.defaultValue}
                placeholder="请输入默认值"
              />
              : <component-selector
                fieldFilter={[...SUPPORT_TAG]}
                componentFilter={[...SUPPORT_TAG]}
                current={this.activeData}
                value={this.activeData.__config__.from}
                onChange={this.handleDataFilter}/>
          }

        </el-form-item>
      </div>
    );
  },
};
