<script>
import common from './mixins';
import optionMixin from './optionMixin';
import Clickoutside from 'element-ui/src/utils/clickoutside';
export default {
  mixins: [common, optionMixin],
  directives: {Clickoutside},
  methods: {
    handleMultipleChange (val) {
      this.$set(this.activeData.__config__, 'defaultValue', val ? [] : '');
    },
  },
  props: {
    activeData: Object,
  },

  render () {
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题"/>
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-select v-model={this.activeData.__config__.defaultValue} placeholder="请输入默认值" >
            {
              this.activeData.__slot__.options ? this.activeData.__slot__.options.filter(t=>t.value).map((item, index)=>{
                return (
                  <el-option value={item.value} label={item.label} key={index}></el-option>
                );
              }) : null
            }
          </el-select>
        </el-form-item>
        <el-form-item label="选项样式">
          <el-radio-group v-model={this.activeData.__config__.optionType}>
            <el-radio-button label="default">
                默认
            </el-radio-button>
            <el-radio-button label="button">
                按钮
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
        <el-divider>选项</el-divider>
        <draggable
          list={this.activeData.__slot__.options}
          animation={340}
          group="selectItem"
          handle=".option-drag"
        >
          {
            this.activeData.__slot__.options ? this.activeData.__slot__.options.map((item, index)=>{
              return (
                <div key={index} class="select-item">
                  <div class="select-line-icon option-drag">
                    <i class="el-icon-s-operation" />
                  </div>
                  <el-input v-model={item.label} placeholder="选项名" disabled size="small" />
                  <el-input
                    disabled
                    placeholder="选项值"
                    size="small"
                    value={item.value}
                    onInput={($event)=>this.setOptionValue(item, $event)}
                  />
                  {
                    !this.inputDisabled
                      ? <div class="close-btn select-line-icon" onClick={()=>this.activeData.__slot__.options.splice(index, 1)}>
                        <i class="el-icon-remove-outline" />
                      </div> : ''
                  }
                </div>
              );
            }) : null
          }
        </draggable>
        <div style="margin-left: 20px;" class="mb-2">
          <el-popover
            placement="top"
            appendToBody={false}
            width="300"
            visible-arrow={false}
            v-clickoutside={this.handleOptionFormCancel}
            v-model={this.optionFormVisible}
            trigger="manual">
            <el-form size="mini" label-width="80px" attrs={{model: this.optionForm}} rules={this.optionFormRules} ref="optionForm">
              <el-form-item width="150" label="显示值" prop="label">
                <el-input v-model={this.optionForm.label}></el-input>
              </el-form-item>
              <el-form-item width="150" label="选项值" prop="value">
                <el-input v-model={this.optionForm.value}></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" onClick={this.handleOptionFormSave}>确定</el-button>
                <el-button onClick={this.handleOptionFormCancel}>取消</el-button>
              </el-form-item>
            </el-form >
            <el-button
              slot="reference"
              style="padding-bottom: 0"
              icon="el-icon-circle-plus-outline"
              type="text" onClick={()=>{ this.optionFormVisible = !this.optionFormVisible; }}>
              添加选项
            </el-button>
          </el-popover>
        </div>
        <el-form-item label-width="0px">
          <el-button type="primary" class="w-100" onClick={()=>{ this.bindDialogVisible = true; }}>关联选项设置</el-button>
        </el-form-item>
        {
          this.bindDialogVisible
            ? <VisibleBindDialog value={this.activeData?.__config__?.valueChangeAction}
              currentWidgetSchema={this.activeData}
              sourceFieldList ={this.currentProcessFieldList}
              onInput={val=>{ this.activeData.__config__.valueChangeAction = val; }}
              onClose={()=>{ this.bindDialogVisible = false; }}>
            </VisibleBindDialog>
            : null
        }
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
@import './common.scss';
</style>
