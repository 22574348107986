import server from '../request';

export default {
  getDeviceList (param) {
    param.orderByProperty = param.orderByProperty || 'lastUpdateTime';
    param.orderByType = param.orderByType || 'DESC';
    param.orderBy = [
      {
        orderByProperty: param.orderByProperty,
        orderByType: param.orderByType,
      },
    ];
    delete param.orderByType;
    delete param.orderByProperty;
    return server.post('/xiaozi-xmb/wireless/saas/device/queryPage', param);
  },

  searchDevice (param) {
    return server.post('/xiaozi-xmb/wireless/saas/device/queryDevice', param, {
      stopDefaultLoading: true,
    });
  },

  getDeviceDetail (param) {
    return server.post('/xiaozi-xmb/wireless/saas/device/details', param);
  },

  // 产品档案
  productArchives (param) {
    return server.post('/xiaozi-xmb/wireless/saas/productArchives/page', param, {
      stopDefaultLoading: true,
    });
  },

  addDevice (param) {
    return server.post('/xiaozi-xmb/wireless/saas/device/add', param);
  },

  updateDevice (param) {
    return server.post('/xiaozi-xmb/wireless/saas/device/edit', param);
  },

  deleteDevice (param) {
    return server.post('/xiaozi-xmb/wireless/saas/device/delete', param);
  },

  queryDeviceSpecFromOrder ({ keyWord, customerId, pageNum, pageSize, orderByProperty, orderByType }, options) {
    let param = { keyWord, pageNum, pageSize, customerId };
    param.orderByProperty = orderByProperty || 'lastUpdateTime';
    param.orderByType = orderByType || 'DESC';
    param.orderBy = [
      {
        orderByProperty: param.orderByProperty,
        orderByType: param.orderByType,
      },
    ];
    delete param.orderByType;
    delete param.orderByProperty;
    return server.post('/xiaozi-xmb/wireless/saas/device/queryDeviceSpeForWork', param, options);
  },

  getDetail (id) {
    return server.post('/xiaozi-saas/wireless/tenant/queryById', { id });
  },

  add ({
    applicantName,
    applicantPhoneNumber,
    applicantPosition,
    name,
    referrerId,
    referrerName,
    scale,
    trialProductId,
    trialProductName,
    type,
  }) {
    return server.post('/xiaozi-saas/wireless/tenant/add', {
      applicantName,
      applicantPhoneNumber,
      applicantPosition,
      name,
      referrerId,
      referrerName,
      scale,
      trialProductId,
      trialProductName,
      type,
    });
  },

  active ({ id, trialPeriod }) {
    return server.post('/xiaozi-saas/wireless/tenant/active', { id, trialPeriod });
  },

  close ({ id, closeReason }) {
    return server.post('/xiaozi-saas/wireless/tenant/close', { id, closeReason });
  },

  delete (id) {
    return server.post('/xiaozi-saas/wireless/tenant/delById', { id });
  },

  resetPwd ({ id, newPwd, oldPwd }) {
    return server.post('xiaozi-saas/wireless/tenant/reset', { id, newPwd, oldPwd });
  },

  approve ({
    id,
    openType,
    adminAccount,
    adminPassword,
    trialPeriod,
    trialStatus,
    applicantName,
    applicantPhoneNumber,
    applicantPosition,
    name,
    referrerId,
    referrerName,
    scale,
    trialProductId,
    trialProductName,
    type,
  }) {
    if (trialStatus === 'wait') {
      return server.post('/xiaozi-saas/wireless/tenant/trialPass', {
        id,
        openType,
        adminAccount,
        adminPassword,
        trialPeriod,
        applicantName,
        applicantPhoneNumber,
        applicantPosition,
        name,
        referrerId,
        referrerName,
        scale,
        trialProductId,
        trialProductName,
        type,
      });
    } else {
      return server.post('/xiaozi-saas/wireless/tenant/activePass', {
        id,
        openType,
        adminAccount,
        adminPassword,
        trialPeriod,
      });
    }
  },

  reject ({ id, rejectReason, status }) {
    if (status === 'wait') {
      return server.post('/xiaozi-saas/wireless/tenant/trialReject', { id, rejectReason });
    } else {
      return server.post('/xiaozi-saas/wireless/tenant/activeReject', { id, rejectReason });
    }
  },

  downloadQrCode (data) {
    return server.post('/xiaozi-xmb/wireless/saas/device/downloadQrCode', data, { responseType: 'blob' });
  },

  subjectList (data) {
    return server.post('xiaozi-xmb/wireless/saas/subject/list', data);
  },
};
