<template>
  <virtual-selector
    :isLoading="isLoading"
    :placeholder="placeholder"
    v-model="defaultValue"
    :name.sync="defaultName"
    :optionList="optionList"
    :readonly="readonly"
    filterable
    remote
    :option="{
      label: 'name',
      key: 'id',
      desc:(item)=>{
        return `${ item.spec } ${ item.serialNumber } ${ item.subordinateDept }`
      }
    }"
    :size="size"
    :remoteMethod="handleSearch"
    :load-more="loadMore"
    @change="handleChange">
  </virtual-selector>
</template>
<script>
import axios from 'axios';
import DeviceApi from '@/api/device';
import VirtualSelector from '@/components/common/virtualSelector';
export default {
  name: 'Device_SELECTOR',
  components: {
    VirtualSelector,
  },
  props: {
    value: {
      type: String,
      require: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    name: {
      type: String,
      require: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '请输入设备名称、规格型号、品牌、设备序列号查询',
    },
  },
  watch: {
    value () {
      this.defaultValue = this.value;
    },
    name () {
      this.defaultName = this.name;
    },
  },
  data () {
    return {
      optionList: [],
      isLoading: false,
      defaultValue: this.value,
      defaultName: this.name,
      pageIndex: 1,
      isReachLast: false,
    };
  },
  methods: {
    getList (keyword) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      const cancelToken = axios.CancelToken;
      this.cancelTokenSource = cancelToken.source();
      this.isLoading = true;
      DeviceApi.queryDeviceSpecFromOrder({
        keyWord: keyword,
        pageNum: this.pageIndex,
        pageSize: 10,
        enable: true,
        customerId: this.customerId,
      }, {
        stopDefaultLoading: true,
        cancelToken: this.cancelTokenSource.token,
      }).then(res => {
        this.isLoading = false;
        this.isReachLast = (res?.body?.list?.length || 0) < 10;
        this.optionList = this.optionList.concat(res?.body?.list || []);
      }).catch((res) => {
        if (res?.heads?.code !== -100) {
          this.isLoading = false;
        }
      });
    },
    handleChange (item) {
      this.$emit('input', item?.id);
      this.$emit('update:name', item?.name);
      this.$emit('change', item || {});
    },
    handleSearch (name) {
      this.pageIndex = 1;
      this.isReachLast = false;
      this.optionList.splice(0, this.optionList.length);
      this.getList(name);
    },
    loadMore (keyword) {
      if (!this.isReachLast) {
        this.pageIndex += 1;
        this.getList(keyword);
      }
    },
  },
};

</script>

<style lang="scss" >
.hospital_search_popper{

  .hospital_search_list {
    width: 100%;
    list-style: none;
    padding: 2px 0;
    margin: 0;
    box-sizing: border-box;
    max-height: 300px;
    overflow-y: auto;
    &>li {
      font-size: 14px;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      position: relative;
      color: #606266;
      height: 34px;
      line-height: 34px;
      box-sizing: border-box;
      cursor: pointer;

      &.selected{
        color: #409EFF;
        font-weight: 700
      }

      &>span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        &:first-child{
          flex: 2;
        }
      }

      &:hover {
        color: #409EFF;
        font-weight: 700
      }
    }
}
}

</style>
