<template>
  <div class="c-spare-part-list">
    <vxe-toolbar ref="xToolbar" v-if="!disabled">
      <template #buttons>
        <el-button type="primary" size="small"  @click="handleInsert">新增</el-button >
      </template>
    </vxe-toolbar>
    <vxe-grid
      ref="xTable"
      size="small"
      border
      align="center"
      auto-resize
      resizable
      show-overflow
      :data="tableData"
      :columns="columns"
      :edit-config="{trigger: 'click', mode: 'cell'}">
      <!-- <vxe-column type="seq" width="60"></vxe-column>
      <vxe-column field="source" title="来源" min-width="60px"  :edit-render="disabled?null:{}">
        <template #default="{ row }">
          <span>{{ sparePartSource[row.source] }}</span>
        </template>
        <template #edit="{ row }">
          <el-select popper-class='vxe-table--ignore-clear' v-model="row.source" size="small" placeholder="请选择" @change="handleSpareTypeChanged(row)">
            <el-option
              v-for="item in Object.keys(sparePartSource)"
              :key="item"
              :label="sparePartSource[item]"
              :value="item">
            </el-option>
          </el-select>
        </template>
      </vxe-column>
      <vxe-column field="name" title="备件名称" min-width="100px" :edit-render="disabled?null:{}">
        <template #edit="{ row }">
          <SpareTypeSelector v-if="row.source==='BACKUP_STORAGE'"
            popper-class='vxe-table--ignore-clear'
            :id="row.sourceId"
            :name.sync="row.name" @change="handleSparePartChanged(row,$event)">
          </SpareTypeSelector>
          <el-input v-else   size="small" v-model="row.name" placeholder="请填写备件名称"></el-input >
        </template>
      </vxe-column>
      <vxe-column field="code" title="备件编码" min-width="100px"  :edit-render="disabled?null:{}">
        <template #edit="{ row }">
          <el-input v-if="row.source!=='BACKUP_STORAGE'" size="small" v-model="row.code" placeholder="请填写备件编码"></el-input >
        </template>
      </vxe-column>
      <vxe-column field="spec" title="规格型号" min-width="100px"  :edit-render="disabled?null:{}">
        <template #edit="{ row }"  >
          <el-input  v-if="row.source!=='BACKUP_STORAGE'" size="small" v-model="row.spec" placeholder="请填写规格型号"></el-input >
        </template>
      </vxe-column>
      <vxe-column field="serialNumber" title="备件序列号" min-width="100px"  :edit-render="disabled?null:{}">
        <template #edit="{ row }"  >
          <el-input v-if="row.source!=='BACKUP_STORAGE'" size="small" v-model="row.serialNumber" placeholder="请填写备件序列号"></el-input >
        </template>
      </vxe-column>
      <vxe-column field="sparePartNumber" title="数量" min-width="80px"  :edit-render="disabled?null:{}">
        <template #edit="{ row }"  >
          <el-input-number v-model="row.sparePartNumber" v-if="row.source!=='BACKUP_STORAGE'" controls-position="right" :min="1" size="small"></el-input-number>
        </template>
      </vxe-column> -->
    </vxe-grid>
  </div>
</template>
<script>
const sparePartSource = {
  MANUALLY_ADDED: '手工新增',
  BACKUP_STORAGE: '备件库',
};
// import SpareTypeSelector from '@/components/spareTypeSlelector';
export default {
  // 备件 系统组件
  name: 'c-spare-part-list',
  components: {
    // SpareTypeSelector,
  },
  data () {
    return {
      tableData: this.value,
      sparePartSource,
      disabled: true,
    };
  },
  props: {
    // disabled: Boolean,// pc在不支持新增备件，暂时禁用
    value: {
      type: Array,
    },
    businessType: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleInsert () {
      // this.tableData.push({
      //   principalName: null,
      //   principalId: null,
      //   maintenanceDate: null,
      //   startTime: null,
      //   endTime: undefined,
      //   maintenanceWork: null,
      //   travelTime: null,
      //   endState: null,
      // });
      this.tableData.push({});
    },
    handleSparePartChanged (row, item) {
      row.code = item.code;
      row.sourceId = null;
      row.name = null;
      row.serialNumber = null;
      row.sparePartNumber = null;
      row.spec = null;
    },
    handleSpareTypeChanged (row) {
      row.code = null;
      row.sourceId = null;
      row.name = null;
      row.serialNumber = null;
      row.sparePartNumber = null;
      row.spec = null;
    },
  },
  computed: {
    columns () {
      if (this.businessType === 1) {
        return [
          {
            type: 'seq',
            title: '序号',
            minWidth: '60px',
          },
          {
            field: 'source',
            title: '来源',
            minWidth: '60px',
            formatter: ({ row })=>sparePartSource[row.source],
          },
          {
            field: 'name',
            minWidth: '100px',
            title: '备件名称',
          },
          {
            field: 'code',
            minWidth: '100px',
            title: '备件编码',
          },
          {
            field: 'spec',
            minWidth: '100px',
            title: '规格型号',
          },
          {
            field: 'serialNumber',
            minWidth: '100px',
            title: '备件序列号',
          },
          {
            field: 'sparePartNumber',
            minWidth: '80px',
            title: '数量',
          },
        ];
      } else {
        return [
          {
            type: 'seq',
            title: '序号',
            minWidth: '60px',
          },
          {
            field: 'source',
            minWidth: '60px',
            title: '来源',
            formatter: ({ row })=>{
              if (row.source === '2') {
                return '公司备件';
              } else if (row.source === '3') {
                return '厂家备件';
              }
            },
          },
          {
            field: 'sparePartName',
            minWidth: '100px',
            title: '备件名称',
          },
          {
            field: 'sparePartCode',
            minWidth: '100px',
            title: '备件编码',
          },
          {
            field: 'spec',
            minWidth: '100px',
            title: '规格型号',
          },
          {
            field: 'serialNumbers',
            minWidth: '100px',
            title: '备件序列号',
            formatter: ({ row })=>row.serialNumbers ? row.serialNumbers.join('、') : '',
          },
          {
            field: 'quantity',
            minWidth: '80px',
            title: '数量',
          },
        ];
      }
    },
  },
  mounted () {
    this.$nextTick(() => {
      // 将表格和工具栏进行关联
      !this.disabled && this.$refs.xTable.connect(this.$refs.xToolbar);
    });
  },
};
</script>
<style scoped lang="scss">
  .c-spare-part-list {
    .el-date-editor {
      width: 100%;
    }
  }
</style>
