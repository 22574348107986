var render = function render(){var _vm=this,_c=_vm._self._c;return _c('virtual-selector',{attrs:{"isLoading":_vm.isLoading,"placeholder":_vm.placeholder,"name":_vm.defaultName,"optionList":_vm.optionList,"readonly":_vm.readonly,"filterable":"","remote":"","option":{
    label: 'name',
    key: 'id',
    desc:(item)=>{
      return `${ item.spec } ${ item.serialNumber } ${ item.subordinateDept }`
    }
  },"size":_vm.size,"remoteMethod":_vm.handleSearch,"load-more":_vm.loadMore},on:{"update:name":function($event){_vm.defaultName=$event},"change":_vm.handleChange},model:{value:(_vm.defaultValue),callback:function ($$v) {_vm.defaultValue=$$v},expression:"defaultValue"}})
}
var staticRenderFns = []

export { render, staticRenderFns }