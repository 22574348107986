import server from '../request';

export default {
  getUserList (data, config) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/page', data, config);
  },

  assign ({ code, id, principalId }) {
    return server.post('/xiaozi-xmb/wireless/saas/workOrder/designate', { code, id, principalId });
  },

  sendHavedRead ({ id, guide }) {
    return server.post('/xiaozi-xmo/wireless/saas/employee/changeGuide', { id, guide });
  },
};
