// 表单属性【右面板】
export const formConf = {
  formRef: 'elForm',
  formModel: 'formData',
  size: 'medium',
  labelPosition: 'right',
  labelWidth: 100,
  formRules: 'rules',
  gutter: 15,
  disabled: false,
  span: 24,
  formBtns: true,
};

// 输入型组件 【左面板】
export const inputComponents = [
  {
    // 组件的自定义配置
    __config__: {
      label: '单行输入框',
      componentName: '单行输入框',
      labelWidth: null,
      showLabel: true,
      tag: 'el-input',
      tagIcon: 'icon-danhangshurukuang',
      defaultValue: undefined,
      required: false,
      layout: 'colFormItem',
      span: 6,
      // 正则校验规则
      regList: [],
      isFixedValue: 1,
    },
    // 组件的插槽属性
    __slot__: {
      prepend: '',
      append: '',
    },
    // 其余的为可直接写在组件标签上的属性
    placeholder: '请输入',
    style: { width: '100%' },
    clearable: true,
    'prefix-icon': '',
    'suffix-icon': '',
    maxlength: null,
    'show-word-limit': false,
    readonly: false,
    disabled: false,
  },
  {
    __config__: {
      label: '多行输入框',
      componentName: '多行输入框',
      labelWidth: null,
      showLabel: true,
      tag: 'el-input',
      tagIcon: 'icon-duohangshurukuang',
      defaultValue: undefined,
      required: false,
      layout: 'colFormItem',
      span: 6,
      regList: [],
      isFixedValue: 1,
    },
    type: 'textarea',
    placeholder: '请输入',
    autosize: {
      minRows: 4,
      maxRows: 4,
    },
    clearable: false,
    style: { width: '100%' },
    maxlength: null,
    'show-word-limit': false,
    readonly: false,
    disabled: false,
  },
  {
    __config__: {
      label: '文本标签',
      componentName: '文本标签',
      labelWidth: null,
      showLabel: true,
      tag: 'span',
      tagIcon: 'icon-wenbenbiaoqian1',
      defaultValue: undefined,
      required: false,
      layout: 'colFormItem',
      isFixedValue: 1, // 文本标签是否是固定值
      from: null, // {key:'renderKey',prop}
      span: 6,
      // 正则校验规则
      regList: [],
    },
    __slot__: {
      default: '文本标签',
    },
    style: {
      minHeight: '36px',
      display: 'block',
    },
  },
  // {
  //   __config__: {
  //     label: '密码',
  //     showLabel: true,
  //     labelWidth: null,
  //     tag: 'el-input',
  //     tagIcon: 'password',
  //     defaultValue: undefined,
  //     layout: 'colFormItem',
  //     span: 6,
  //     required: true,
  //     regList: [],
  //   },
  //   __slot__: {
  //     prepend: '',
  //     append: '',
  //   },
  //   placeholder: '请输入',
  //   'show-password': true,
  //   style: { width: '100%' },
  //   clearable: true,
  //   'prefix-icon': '',
  //   'suffix-icon': '',
  //   maxlength: null,
  //   'show-word-limit': false,
  //   readonly: false,
  //   disabled: false,
  // },
  {
    __config__: {
      label: '数值',
      componentName: '数值',
      showLabel: true,
      labelWidth: null,
      tag: 'el-input-number',
      tagIcon: 'icon-shuzhi',
      defaultValue: undefined,
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
    },
    placeholder: '',
    min: undefined,
    max: undefined,
    step: 1,
    'step-strictly': false,
    precision: 0,
    'controls-position': '',
    disabled: false,
  },
  // {
  //   __config__: {
  //     label: '编辑器',
  //     showLabel: true,
  //     labelWidth: null,
  //     tag: 'tinymce',
  //     tagIcon: 'rich-text',
  //     defaultValue: null,
  //     span: 6,
  //     layout: 'colFormItem',
  //     required: true,
  //     regList: [],
  //   },
  //   placeholder: '请输入',
  //   height: 300, // 编辑器高度
  //   branding: false, // 隐藏右下角品牌烙印
  // },
  {
    __config__: {
      label: '手工签名',
      componentName: '手工签名',
      showLabel: true,
      labelWidth: null,
      tag: 'c-manual-sign',
      tagIcon: 'icon-shougongqianming',
      defaultValue: null,
      layout: 'colFormItem',
      required: false,
      regList: [],
      span: 24,
    },
  },
];

// 选择型组件 【左面板】
export const selectComponents = [
  {
    __config__: {
      label: '下拉选择',
      componentName: '下拉选择',
      showLabel: true,
      labelWidth: null,
      tag: 'el-select',
      tagIcon: 'icon-xialaxuanze',
      layout: 'colFormItem',
      span: 6,
      required: false,
      regList: [],
    },
    __slot__: {
      options: [
        {
          label: '选项一',
          value: '1',
        },
        {
          label: '选项二',
          value: '2',
        },
      ],
    },
    'value-key': 'value',
    placeholder: '请选择',
    style: { width: '100%' },
    clearable: true,
    disabled: false,
    filterable: false,
    multiple: false,
  },
  // {
  //   __config__: {
  //     label: '级联选择',
  //     url: 'https://www.fastmock.site/mock/f8d7a54fb1e60561e2f720d5a810009d/fg/cascaderList',
  //     method: 'get',
  //     dataPath: 'list',
  //     dataConsumer: 'options',
  //     showLabel: true,
  //     labelWidth: null,
  //     tag: 'el-cascader',
  //     tagIcon: 'cascader',
  //     layout: 'colFormItem',
  //     defaultValue: [],
  //     dataType: 'dynamic',
  //     span: 6,
  //     required: true,
  //     regList: [],
  //   },
  //   options: [{
  //     id: 1,
  //     value: 1,
  //     label: '选项1',
  //     children: [{
  //       id: 2,
  //       value: 2,
  //       label: '选项1-1',
  //     }],
  //   }],
  //   placeholder: '请选择',
  //   style: { width: '100%' },
  //   props: {
  //     props: {
  //       multiple: false,
  //       label: 'label',
  //       value: 'value',
  //       children: 'children',
  //     },
  //   },
  //   'show-all-levels': true,
  //   disabled: false,
  //   clearable: true,
  //   filterable: false,
  //   separator: '/',
  // },
  {
    __config__: {
      label: '单选框组',
      componentName: '单选框组',
      labelWidth: null,
      showLabel: true,
      tag: 'el-radio-group',
      tagIcon: 'icon-danxuan1',
      defaultValue: undefined,
      layout: 'colFormItem',
      span: 6,
      optionType: 'default',
      regList: [],
      required: false,
      border: false,
    },
    __slot__: {
      options: [
        {
          label: '选项一',
          value: '1',
        },
        {
          label: '选项二',
          value: '2',
        },
      ],
    },
    style: {},
    size: 'medium',
    disabled: false,
  },
  {
    __config__: {
      label: '多选框组',
      componentName: '多选框组',
      tag: 'el-checkbox-group',
      tagIcon: 'icon-duoxuan',
      defaultValue: [],
      span: 6,
      showLabel: true,
      labelWidth: null,
      layout: 'colFormItem',
      optionType: 'default',
      false: true,
      regList: [],
      border: false,
    },
    __slot__: {
      options: [
        {
          label: '选项一',
          value: '1',
        },
        {
          label: '选项二',
          value: '2',
        },
      ],
    },
    style: {},
    size: 'medium',
    min: null,
    max: null,
    disabled: false,
  },
  {
    __config__: {
      label: '开关',
      componentName: '开关',
      tag: 'el-switch',
      tagIcon: 'icon-kaiguan',
      defaultValue: false,
      span: 6,
      showLabel: true,
      labelWidth: null,
      layout: 'colFormItem',
      false: true,
      regList: [],
    },
    style: {},
    disabled: false,
    'active-text': '',
    'inactive-text': '',
    'active-color': null,
    'inactive-color': null,
    'active-value': true,
    'inactive-value': false,
  },
  {
    __config__: {
      label: '滑块',
      componentName: '滑块',
      tag: 'el-slider',
      tagIcon: 'icon-huakuai',
      defaultValue: null,
      span: 6,
      showLabel: true,
      layout: 'colFormItem',
      labelWidth: null,
      required: false,
      regList: [],
    },
    disabled: false,
    min: 0,
    max: 100,
    step: 1,
    'show-stops': false,
    range: false,
  },
  {
    __config__: {
      label: '时间选择',
      componentName: '时间选择',
      tag: 'el-time-picker',
      tagIcon: 'icon-shijianxuanze',
      defaultValue: null,
      span: 6,
      showLabel: true,
      layout: 'colFormItem',
      labelWidth: null,
      required: false,
      regList: [],
    },
    placeholder: '请选择',
    style: { width: '100%' },
    disabled: false,
    clearable: true,
    'picker-options': {
      selectableRange: '00:00:00-23:59:59',
    },
    format: 'HH:mm:ss',
    'value-format': 'timestamp',
  },
  {
    __config__: {
      label: '时间范围',
      componentName: '时间范围',
      tag: 'el-time-picker',
      tagIcon: 'icon-shijianfanwei',
      span: 6,
      showLabel: true,
      labelWidth: null,
      layout: 'colFormItem',
      defaultValue: null,
      required: false,
      regList: [],
    },
    style: { width: '100%' },
    disabled: false,
    readonly: false,
    clearable: true,
    'is-range': true,
    'range-separator': '至',
    format: 'HH:mm:ss',
    'value-format': 'timestamp',
  },
  {
    __config__: {
      label: '日期选择',
      componentName: '日期选择',
      tag: 'el-date-picker',
      tagIcon: 'icon-riqixuanze',
      defaultValue: null,
      showLabel: true,
      labelWidth: null,
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
    },
    placeholder: '请选择',
    type: 'date',
    style: { width: '100%' },
    disabled: false,
    clearable: true,
    format: 'yyyy-MM-dd',
    'value-format': 'timestamp',
    readonly: false,
  },
  {
    __config__: {
      label: '日期范围',
      componentName: '日期范围',
      tag: 'el-date-picker',
      tagIcon: 'icon-riqifanwei',
      defaultValue: null,
      span: 6,
      showLabel: true,
      labelWidth: null,
      required: false,
      layout: 'colFormItem',
      regList: [],
    },
    style: { width: '100%' },
    type: 'daterange',
    'range-separator': '至',
    disabled: false,
    clearable: true,
    format: 'yyyy-MM-dd',
    'value-format': 'timestamp',
    readonly: false,
  },
  {
    __config__: {
      label: '评分',
      componentName: '评分',
      tag: 'el-rate',
      tagIcon: 'icon-pingfen',
      defaultValue: 0,
      span: 6,
      showLabel: true,
      labelWidth: null,
      layout: 'colFormItem',
      required: false,
      regList: [],
    },
    style: {},
    max: 5,
    'allow-half': false,
    'show-text': false,
    'show-score': false,
    disabled: false,
  },
  {
    __config__: {
      label: '上传',
      componentName: '上传',
      tag: 'upload',
      tagIcon: 'icon-shangchuan',
      layout: 'colFormItem',
      defaultValue: [],
      showLabel: true,
      labelWidth: null,
      required: false,
      span: 24,
      showTip: false,
      regList: [],
    },
    size: 'small',
    disabled: false,
    accept: null,
    name: 'file',
    type: 'list',
    multiple: true,
    maxSize: 5000,
    buttonText: '选取文件',
  },
  {
    __config__: {
      label: '地址',
      componentName: '地址',
      tag: 'c-province-address',
      tagIcon: 'icon-dizhi',
      labelWidth: null,
      showLabel: true,
      layout: 'colFormItem',
      required: false,
      province: '',
    },
    styleBasic: {
      marginTop: '10px',
    },
    disabled: false,
    addresstype: 3,
    addressPlaceholder: '请输入详细地址',
    clearable: true,
  },
  {
    __config__: {
      label: '定位',
      componentName: '定位',
      tag: 'c-location',
      tagIcon: 'icon-dizhi',
      labelWidth: null,
      showLabel: true,
      layout: 'colFormItem',
      required: false,
      province: '',
      defaultValue: {
        detailAddress: '',
      },
      settingTemplate: 'c-contact-time-config',
    },
    disabled: false,
  },
  // {
  //   __config__: {
  //     label: '颜色选择',
  //     tag: 'el-color-picker',
  //     tagIcon: 'color',
  //     span: 6,
  //     defaultValue: null,
  //     showLabel: true,
  //     labelWidth: null,
  //     layout: 'colFormItem',
  //     required: true,
  //     regList: [],
  //   },
  //   'show-alpha': false,
  //   'color-format': '',
  //   disabled: false,
  //   size: 'medium',
  // },
];

// 布局型组件 【左面板】
export const layoutComponents = [
  {
    __config__: {
      layout: 'groupRowFormItem',
      tagIcon: 'row',
      label: '分组',
      componentName: '分组',
      children: [],
      settingTemplate: 'group-config',
    },
    type: 'default',
    justify: 'start',
    align: 'top',
    identifier: t=>{
      return JSON.stringify({layout: t?.__config__?.layout, releatedType: t.releatedType});
    },
  },
  {
    __config__: {
      showLabel: true,
      layout: 'tableColumnFormItem',
      tagIcon: 'row',
      label: '业务组件',
      componentName: '业务组件',
      children: [],
      settingTemplate: 'businessComponentConfig',
    },
    id: '',
    type: 'default',
    justify: 'start',
    align: 'top',
    sumBy: [],
  },
  {
    __config__: {
      showLabel: true,
      layout: 'childFormItem',
      tagIcon: 'icon-danhangshurukuang',
      label: '关联引用',
      componentName: '关联引用',
      children: [],
      settingTemplate: 'childFromConfig',
    },
    id: '',
    type: 'default',
    justify: 'start',
    align: 'top',
    relatedFormType: null,
    relatedFormTemplateId: null,
    relatedFormTemplateCode: null,
    allowCreate: true,
    releatedType: 1,
    identifier: t=>{
      return JSON.stringify({layout: t?.__config__?.layout, releatedType: t.releatedType});
    },
  },
  {
    __config__: {
      showLabel: true,
      layout: 'childFormItem',
      tagIcon: 'icon-danhangshurukuang',
      label: '关联查询',
      componentName: '关联查询',
      children: [],
      settingTemplate: 'childFromConfig',
    },
    id: '',
    type: 'default',
    justify: 'start',
    align: 'top',
    relatedFormType: null,
    relatedFormTemplateId: null,
    relatedFormTemplateCode: null,
    allowCreate: true,
    identifier: t=>{
      return JSON.stringify({layout: t?.__config__?.layout, releatedType: t.releatedType});
    },
  },
];

// 自定义组件
export const customComponents = [
  {
    __config__: {
      label: '部门',
      componentName: '部门',
      showLabel: true,
      labelWidth: null,
      tag: 'c-department-selector',
      tagIcon: 'icon-bumen',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
    },
    disabled: false,
  },
  {
    __config__: {
      label: '人员',
      componentName: '人员',
      showLabel: true,
      labelWidth: null,
      tag: 'c-user-selector',
      tagIcon: 'icon-renyuan',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
    },
    disabled: false,
  },
  {
    __config__: {
      label: '联系时间',
      componentName: '联系时间',
      showLabel: true,
      labelWidth: null,
      tag: 'c-contact-time',
      tagIcon: 'icon-lianxishijian',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      defaultValue: {
        contactableType: 'ANY_TIME',
      },
    },
    disabled: false,
  },
  {
    __config__: {
      label: '医院档案',
      componentName: '医院档案',
      showLabel: true,
      labelWidth: null,
      tag: 'c-customer-selector',
      tagIcon: 'icon-kehudangan',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
    },
    disabled: false,
  },
  {
    __config__: {
      label: '医院联系人',
      componentName: '医院联系人',
      showLabel: true,
      labelWidth: null,
      tag: 'c-contact-selector',
      tagIcon: 'icon-kehulianxiren1',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
      filterParam: {
        customerId: {
          desc: '医院来源',
          source: null, // {key:'renderKey',prop:''}
        },
      },
    },
    disabled: false,
  },
  {
    __config__: {
      label: '服务商档案',
      componentName: '服务商档案',
      showLabel: true,
      labelWidth: null,
      tag: 'c-service-provider',
      tagIcon: 'icon-fuwushangdangan',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
    },
    disabled: false,
  },
  {
    __config__: {
      label: '公共企业库',
      componentName: '公共企业库',
      showLabel: true,
      labelWidth: null,
      tag: 'c-common-enterprise',
      tagIcon: 'icon-gonggongqiyeku',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
    },
    disabled: false,
  },
  {
    __config__: {
      label: '联系人地址',
      componentName: '联系人地址',
      showLabel: true,
      labelWidth: null,
      tag: 'c-contact-address-selector',
      tagIcon: 'icon-kehulianxiren',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
      filterParam: {
        contactPersonId: {
          desc: '联系人id',
          source: null, // {key:'renderKey',prop:''}
        },
      },
    },
    disabled: false,
  },
  {
    __config__: {
      label: '公共设备库',
      componentName: '公共设备库',
      showLabel: true,
      labelWidth: null,
      tag: 'c-device-common-selector',
      tagIcon: 'icon-shebeidangan',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
    },
    disabled: false,
  },
  {
    __config__: {
      label: '设备档案',
      componentName: '设备档案',
      showLabel: true,
      labelWidth: null,
      tag: 'c-device-selector',
      tagIcon: 'icon-shebeidangan',
      span: 6,
      layout: 'colFormItem',
      required: false,
      regList: [],
      settingTemplate: 'c-user-selector-config',
      filterParam: {
        customerId: {
          desc: '医院来源',
          source: null, // {key:'renderKey',prop:''}
        },
      },
    },
    disabled: false,
  },
  {
    __config__: {
      label: '工时统计',
      componentName: '工时统计',
      showLabel: true,
      labelWidth: null,
      tag: 'c-work-hour',
      tagIcon: 'icon-gongshitongji',
      span: 24,
      layout: 'colFormItem',
      required: false,
      settingTemplate: 'businessComponentConfig',
    },
    disabled: false,
  },
  {
    __config__: {
      label: '备件',
      componentName: '备件',
      showLabel: true,
      labelWidth: null,
      tag: 'c-spare-part-list',
      tagIcon: 'icon-beijian',
      span: 24,
      layout: 'colFormItem',
      required: false,
      settingTemplate: 'businessComponentConfig',
    },
    businessType: 1,
    disabled: false,
  },
  {
    __config__: {
      label: '保养项目',
      componentName: '保养项目',
      showLabel: true,
      labelWidth: null,
      tag: 'c-maintenance-list',
      tagIcon: 'icon-baoyangxiangmu',
      span: 24,
      layout: 'colFormItem',
      required: false,
      settingTemplate: 'businessComponentConfig',
    },
    disabled: false,
  },
  // 基础档案 | 产品档案 | 设备档案 ......
  {
    __config__: {
      label: '基础档案',
      componentName: '基础档案',
      showLabel: true,
      labelWidth: null,
      tag: 'c-archive-collection',
      tagIcon: 'icon-baoyangxiangmu',
      span: 6,
      layout: 'colFormItem',
      required: false,
      settingTemplate: 'c-archive-collection-config',
      defaultValue: undefined,
    },
    disabled: false,
    archiveType: '',
    configCode: '',
  },
  {
    __config__: {
      label: '模板类型',
      componentName: '模板类型',
      showLabel: true,
      labelWidth: null,
      tag: 'c-template-selector',
      tagIcon: 'icon-baoyangxiangmu',
      span: 6,
      layout: 'colFormItem',
      required: false,
      settingTemplate: 'templateTypeConfig',
      defaultValue: undefined,
    },
    disabled: false,
    relatedFormType: null,
  },
];
