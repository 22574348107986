<script>
import _ from 'lodash';
import mixin from './mixin';
import { getCommonQueryPageList } from '@/api/commonForm';
import axios from 'axios';
import VirtualSelector from '@/components/common/virtualSelector';
export default {
  // 基础档案类型 系统组件
  name: 'cArchiveCollection',
  components: {
    VirtualSelector,
  },
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
    },
    archiveType: {
      type: String,
    },
  },
  watch: {
    value () {
      this.defaultValue = this.value?.id;
      this.defaultName = this.value?.name;
    },
  },
  data () {
    return {
      archiveList: [],
      defaultCode: _.cloneDeep(this.value),
      loading: false,
      defaultValue: this.value?.id,
      defaultName: this.value?.name,
      pageIndex: 1,
      isReachLast: false,
    };
  },
  methods: {
    handleChange (item) {
      this.$emit('input', item || {});
    },
    handleSearch (name) {
      this.pageIndex = 1;
      this.isReachLast = false;
      this.archiveList.splice(0, this.archiveList.length);
      this.getBasicHandle(name);
    },
    loadMore (keyword) {
      if (!this.isReachLast) {
        this.pageIndex += 1;
        this.getBasicHandle(keyword);
      }
    },
    getBasicHandle (name) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      const cancelToken = axios.CancelToken;
      this.cancelTokenSource = cancelToken.source();
      this.loading = true;
      getCommonQueryPageList({
        systemConfigCodeFlag: 'BASE_FILE',
        type: this.archiveType,
        pageNum: this.pageIndex,
        pageSize: 10,
        orderBy: [{
          orderByProperty: 'code',
          orderByType: 'ASC',
        }],
        name,
      }, {
        stopDefaultLoading: true,
        cancelToken: this.cancelTokenSource.token,
      }).then(res => {
        this.loading = false;
        this.isReachLast = (res?.body?.list?.length || 0) < 10;
        this.archiveList = this.archiveList.concat(res?.body?.list || []);
      }).catch((res) => {
        if (res?.heads?.code !== -100) {
          this.loading = false;
        }
      });
    },
  },
  render () {
    return (
      <VirtualSelector
        isLoading={this.loading}
        placeholder={this.placeholder}
        v-model={this.defaultValue}
        optionList={this.archiveList}
        name={this.defaultName}
        on={{
          'update:name': (val)=>{ this.defaultName = val; },
        }}
        readonly={this.inputDisabled}
        filterable={true}
        remote={true}
        option={{
          label: 'name',
          key: 'id',
        }}
        size={this.size}
        remoteMethod={this.handleSearch}
        loadMore={this.loadMore}
        onChange={this.handleChange}>
      </VirtualSelector>
    );
  },
};
</script>
