export default {
  'c-department-selector': [
    {
      field: 'id',
      desc: '部门ID',
      type: 'el-input-number',
    },
    {
      field: 'name',
      desc: '部门名称',
      type: 'el-input',
    },
    {
      field: 'code',
      desc: '部门编码',
      type: 'el-input',
    },
    {
      field: 'level',
      desc: '部门层级',
      type: 'el-input-number',
    },
    {
      field: 'pid',
      desc: '父级部门ID',
      type: 'el-input-number',
    },
  ],
  'c-user-selector': [
    {
      field: 'id',
      desc: '人员ID',
      type: 'el-input-number',
    },
    {
      field: 'name',
      desc: '人员名称',
      type: 'el-input',
    },
    {
      field: 'departmentId',
      desc: '所在部门ID',
      type: 'el-input-number',
    },
    {
      field: 'departmentName',
      desc: '所在部门名称',
      type: 'el-input',
    },
  ],
  'c-customer-selector': [
    {
      field: 'id',
      desc: '医院ID',
      type: 'el-input-number',
    },
    {
      field: 'name',
      desc: '医院名称',
      type: 'el-input',
    },
    {
      field: 'address',
      desc: '详细地址',
      type: 'el-input',
    },
  ],
  'c-contact-selector': [
    {
      field: 'id',
      desc: '联系人ID',
      type: 'el-input-number',
    },
    {
      field: 'name',
      desc: '联系人名称',
      type: 'el-input',
    },
    {
      field: 'phone',
      desc: '联系人电话',
      type: 'el-input',
    },
  ],
  'c-contact-address-selector': [
    {
      field: 'address',
      desc: '地址',
      type: 'el-input',
    },
    {
      field: 'id',
      desc: '地址ID',
      type: 'el-input-number',
    },
  ],
  'c-device-common-selector': [
    {
      field: 'id',
      desc: '设备ID',
      type: 'el-input-number',
    },
    {
      field: 'name',
      desc: '设备名称',
      type: 'el-input',
    },
    {
      field: 'spec',
      desc: '规格型号',
      type: 'el-input',
    },
    {
      field: 'classification',
      desc: '设备分类',
      type: 'el-input',
    },
    {
      field: 'brand',
      desc: '品牌',
      type: 'el-input',
    },
    {
      field: 'enterpriseName',
      desc: '生产商',
      type: 'el-input',
    },
    {
      field: 'registerCode',
      desc: '注册证号',
      type: 'el-input',
    },
  ],
  'c-device-selector': [
    {
      field: 'id',
      desc: '设备ID',
      type: 'el-input-number',
    },
    {
      field: 'name',
      desc: '设备名称',
      type: 'el-input',
    },
    {
      field: 'code',
      desc: '设备编号',
      type: 'el-input',
    },
    {
      field: 'spec',
      desc: '设备型号',
      type: 'el-input',
    },
    {
      field: 'serialNumber',
      desc: '设备序列号',
      type: 'el-input',
    },
    {
      field: 'subordinateDept',
      desc: '归属科室',
      type: 'el-input',
    },
    {
      field: 'serviceProviderId',
      desc: '服务商ID',
      type: 'el-input-number',
    },
    {
      field: 'brand',
      desc: '品牌',
      type: 'el-input',
    },
    {
      field: 'subordinateDeptMsg',
      desc: '所属科室',
      type: 'c-department-selector',
    },
    {
      field: 'statusMsg',
      desc: '状态',
      type: 'el-select',
      typeProps: {
        multiple: false,
      },
    },
  ],
  'c-service-provider': [
    {
      field: 'name',
      desc: '企业名称',
      type: 'el-input',
    },
    {
      field: 'unifiedSocialCreditCode',
      desc: '统一社会信用代码',
      type: 'el-input',
    },
  ],
  'c-common-enterprise': [
    {
      field: 'Name',
      desc: '企业名称',
      type: 'el-input',
    },
    {
      field: 'CreditCode',
      desc: '统一社会信用代码',
      type: 'el-input',
    },
    {
      field: 'OperName',
      desc: '法定代表人',
      type: 'el-input',
    },
    {
      field: 'StartDate',
      desc: '成立日期',
      type: 'el-input',
    },
    {
      field: 'Status',
      desc: '登记状态',
      type: 'el-input',
    },
  ],
};
