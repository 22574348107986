import Clickoutside from 'element-ui/src/utils/clickoutside';
import { flatternFields } from '../../utils/component';
import VisibleBindDialog from '../common/VisibleBindDialog.vue';

export default {
  directives: {Clickoutside},
  components: {
    VisibleBindDialog,
  },
  methods: {
    handleOptionFormSave () {
      this.$refs.optionForm.validate(valid=>{
        if (valid) {
          this.activeData.__slot__.options.push({
            ...this.optionForm,
          });
          this.handleOptionFormCancel();
        }
      });
    },
    handleOptionFormCancel () {
      this.optionFormVisible = false;
      this.$refs.optionForm.resetFields();
    },
  },
  inject: ['getFieldList'],
  data () {
    return {
      optionFormVisible: false,
      optionForm: {
        label: null,
        value: null,
      },
      optionFormRules: {
        label: [
          { required: true, message: '请输入显示值', trigger: ['change', 'blur'] },
        ],
        value: [
          { required: true, message: '请输入选项值', trigger: ['change', 'blur'] },
        ],
      },
      bindDialogVisible: false,
    };
  },
  computed: {
    currentProcessFieldList () {
      let filedMap = {};
      flatternFields(this.getFieldList() || [], filedMap);
      return Object.keys(filedMap).map(t=>filedMap[t]);
    },
  },
};
