import common from './mixins';
import ComponentSelector from '../common/componentSelector.vue';

export default {
  mixins: [common],
  components: {
    ComponentSelector,
  },
  methods: {
    dateTypeChange (val) {
      let format = 'yyyy-MM-dd';
      if (val === 'date' || val === 'daterange') {
        format = 'yyyy-MM-dd';
      } else if (val === 'datetime' || val === 'datetimerange') {
        format = 'yyyy-MM-dd HH:mm:ss';
      }
      this.$set(this.activeData, 'format', format);
    },
  },
  props: {
    activeData: Object,
  },
  computed: {
    dateOptions () {
      if (!this.isRange) {
        return [
          {
            label: '日(date)',
            value: 'date',
          },
          {
            label: '日期时间(datetime)',
            value: 'datetime',
          },
        ];
      } else {
        return [
          {
            label: '日期范围(daterange)',
            value: 'daterange',
          },
          {
            label: '日期时间范围(datetimerange)',
            value: 'datetimerange',
          },
        ];
      }
    },
    isRange () {
      return this.activeData['type'] === 'daterange' || this.activeData['type'] === 'datetimerange';
    },
  },
  render () {
    let componentFilter = t => {
      return t.__config__.renderKey !== this.activeData.__config__.renderKey
          && t.__config__.tag === this.activeData.__config__.tag
          && t.type === this.activeData.type;
    };
    return (
      <div>
        <el-form-item label="标题">
          <el-input v-model={this.activeData.__config__.label} placeholder="请输入标题" onInput={this.changeRenderKey} />
        </el-form-item>
        <el-form-item label="显示标题" >
          <el-switch v-model={this.activeData.__config__.showLabel} />
        </el-form-item>
        <el-form-item label="占位提示">
          <el-input v-model={this.activeData.placeholder} placeholder="请输入占位提示" onInput={this.changeRenderKey} />
        </el-form-item>
        <el-form-item label="表单栅格">
          <el-radio-group class="fixed" v-model={this.activeData.__config__.span}>
            <el-radio-button label={6}></el-radio-button>
            <el-radio-button label={8}></el-radio-button>
            <el-radio-button label={12}></el-radio-button>
            <el-radio-button label={24}></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="默认值">
          <el-radio-group v-model={this.activeData.__config__.isFixedValue} onChange={this.handleDefaultValueTypeChange}>
            <el-radio-button label={1}>固定</el-radio-button>
            <el-radio-button label={0}>动态</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item >
          {
            this.activeData.__config__.isFixedValue !== undefined
              ? this.activeData.__config__.isFixedValue
                ? this.isRange
                  ? <el-date-picker v-model={this.activeData.__config__.defaultValue}
                    class="w-100"
                    value-format={this.activeData['value-format']}
                    placeholder="请选择默认值"
                    type={this.activeData.type}
                    format={this.activeData.format}>
                  </el-date-picker>
                  : <el-radio-group v-model={this.activeData.__config__.defaultValue}>
                    <el-radio label="${currentDate}">当前日期</el-radio>
                    <el-radio label="${MonthOfFirstDay}">本月第一天</el-radio>
                    <el-radio label="${MonthOfLastDay}">本月最后一天</el-radio>
                  </el-radio-group>
                : <component-selector componentFilter={componentFilter}
                  fieldFilter={[]}
                  current={this.activeData}
                  value={this.activeData.__config__.from}
                  onChange={this.handleDataFilter}/>

              : null
          }
        </el-form-item>
        <el-form-item label="时间类型">
          <el-select
            v-model={this.activeData.type}
            placeholder="请选择时间类型"
            style={{ width: '100%' }}
            onChange={this.dateTypeChange}
          >
            {
              this.dateOptions.map((item, index)=>(
                <el-option
                  key={index}
                  label={item.label}
                  value={item.value}
                />
              ))
            }
          </el-select>
        </el-form-item>
        <el-form-item label="时间格式">
          {this.activeData.format}
        </el-form-item>
        {
          this.isRange
            ? <el-form-item label="分隔符">
              <el-input v-model={this.activeData['range-separator']} placeholder="请输入分隔符" />
            </el-form-item>
            : null
        }
        <el-form-item label="是否必填">
          <el-switch v-model={this.activeData.__config__.required} />
        </el-form-item>
      </div>
    );
  },
};
